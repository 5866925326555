// service
import http from "@/app/config/http"

class Mailer {
    // base path uri
    path = "mailer/"

    message (data) {
        return http.post(this.path + "message", data)
    }

    newsletter (data) {
        return http.post(this.path + "newsletter", data)
    }
}

export default new Mailer()

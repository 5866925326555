class Message {
    // authentication
    unauthorized = "Accès non autorisé. Réservé Administrateur..."
    denied = "Niveau d'accès manquant. Accès non autorisé..."
    logout = "Déconnection réussie..."

    // content management
    created = "Ajouté avec succès..."
    updated = "Modifié avec succès..."
    destroyed = "Supprimé avec succès..."

    // error
    failed = "Une erreur est survenue... Veuillez réessayer."
    error = "Une erreur fatale est survenue... Contacter l'administrateur."

    // mailer
    sent = "Message envoyé avec succès..."
    notsent = "Message non envoyé..."
}

export default new Message()
